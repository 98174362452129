import React, { useState } from "react"
import { Formik, Form } from "formik"
import styled from "styled-components"

import AccountsFormInputField from "../accounts-form-input-field"

// ======================
// 	     COMPONENT
// ======================
const PasswordSubmit = ({ ExtraStyled, submitHandler, message }) => {
  const [hidePassword, setHidePassword] = useState(true)

  return (
    <>
      {!message && <ExtraStyled>Enter your new password below.</ExtraStyled>}
      <Formik
        initialValues={{ password: "" }}
        validate={validate}
        onSubmit={submitHandler}
      >
        {({ isSubmitting, errors, touched }) => (
          <>
            <Form>
              <AccountsFormInputField
                label="Password:"
                type={hidePassword ? "password" : "text"}
                hidePassword={hidePassword}
                name="password"
                autoComplete="new-password"
                errors={errors}
                touched={touched}
                toggle={() => {
                  setHidePassword((prevState) => !prevState)
                }}
              />
              <AccountsFormInputField
                label="Confirm Password:"
                type={hidePassword ? "password" : "text"}
                hidePassword={hidePassword}
                name="passwordConfirm"
                autoComplete="new-password"
                toggle={() => {
                  setHidePassword((prevState) => !prevState)
                }}
                errors={errors}
                touched={touched}
              />
              <StyledButton type="submit">RESET</StyledButton>
            </Form>
          </>
        )}
      </Formik>
    </>
  )
}

// ===============
//     STYLES
// ===============
const StyledButton = styled.button`
  margin: 20px 0;
  width: 100%;
`

// ======================
// 	🔧🔧 HELPERS 🔧🔧
// ======================

const validate = ({ password, passwordConfirm }) => {
  const errors = {}
  const addIf = (pred, prop, message) => pred && (errors[prop] = message)

  addIf(!password, "password", "Password is required")
  addIf(password !== passwordConfirm, "password", "Passwords do not match")
  addIf(
    password.length < 6,
    "password",
    "Password should be at least 6 characters"
  )

  return errors
}

export default PasswordSubmit
