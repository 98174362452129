import React from "react"

import Layout from "../theme/layout"
import SEO from "../components/header/seo"
import PasswordResetPanel from "../components/account/password-reset/password-reset-panel"

const ResetPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Reset Password" location={location} />
      <PasswordResetPanel />
    </Layout>
  )
}

export default ResetPage
